for (let form of document.querySelectorAll(".forma")) {
    form.addEventListener("submit", function (e) {
        e.preventDefault(); // Отключаем стандартное поведение

        const formData = new FormData(this);

        fetch(this.action, {
            method: this.method,
            body: formData,
        })
            .then(() => {
                alert("Форма успешно отправлена!");
                this.reset(); // Очищаем форму
            })
            .catch(() => alert("Ошибка отправки формы. Попробуйте снова."));
    });
}
