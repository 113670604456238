let progress = 0;
let index = -1;

const stepsDescriptions = document.querySelectorAll(".steps__description");
const progressItems = document.querySelectorAll(".steps__progress-item");
const progressItemsDesctop = document.querySelectorAll(".steps__progress-bar-desktop .steps__progress-item");
const progressItemsMobile = document.querySelectorAll(".steps__progress-bar-mobile .steps__progress-item");
const progressBar = document.querySelectorAll(".steps__progress");

let stepClasses = [0, 3, 1, 4, 2, 5];

if (window.innerWidth < 768) {
    stepClasses = [0, 1, 2, 3, 4, 5];
}

function addClass(index) {
    document.querySelector(".steps__description_active")?.classList.remove("steps__description_active");
    stepsDescriptions[index]?.classList.add("steps__description_active");
}

setInterval(() => {
    if (progress > 87) {
        progress = 0;
        index = -1;
        progressItems.forEach((item) => {
            item.classList.remove("steps__progress-item_active", "steps__progress-item_padding");
        });
    } else {
        progress += 19.8;
        index++;
    }
    for (let bar of progressBar) {
        if (window.innerWidth > 768) {
            bar.style.width = `${progress}%`;
        } else {
            bar.style.height = `${progress}%`;
        }
    }

    if (index !== -1) {
        if (window.innerWidth > 768) {
            progressItemsDesctop[index]?.classList.add("steps__progress-item_active");
        } else {
            progressItemsMobile[index]?.classList.add("steps__progress-item_active");
        }
    }
    if (window.innerWidth > 768) {
        progressItemsDesctop[index + 1]?.classList.add("steps__progress-item_padding");
    } else {
        progressItemsMobile[index + 1]?.classList.add("steps__progress-item_padding");
    }

    if (index + 1 >= 0 && index + 1 <= 5) {
        addClass(stepClasses[index + 1]);
    }
}, 3000);
