// График увеличения портов по неделям
const schedule = [1, 2, 0, 1, 0, 1, 1, 0];

// Год и неделя, с которых начинаются вычисления
const startYear = 2024;
const startWeek = 21;

// Начальное количество портов на стартовой неделе
const initialPorts = 37;

// Функция для расчета текущего количества портов
function calculatePorts(initialPorts, schedule, startYear, startWeek) {
    // Получить текущую дату и текущий год
    const today = new Date();
    const currentYear = today.getFullYear();

    // Рассчитать количество полных лет и недель с начала года
    const start = new Date(currentYear, 0, 1);
    const diff = today - start + (start.getTimezoneOffset() - today.getTimezoneOffset()) * 60000;
    const oneWeek = 604800000; // Миллисекунды в одной неделе
    const currentWeek = Math.floor(diff / oneWeek) + 1;

    // Рассчитать общее количество портов с учетом прошедших лет и недель
    let totalPorts = initialPorts;

    // Обработка каждого года начиная с года начала вычислений
    for (let year = startYear; year <= currentYear; year++) {
        const isCurrentYear = year === currentYear;
        const endWeek = isCurrentYear ? currentWeek : 52;

        // Если это год начала вычислений, начинаем с недели startWeek, иначе с первой недели
        const startWeekForYear = year === startYear ? startWeek : 1;

        for (let week = startWeekForYear; week <= endWeek; week++) {
            totalPorts += schedule[(week - 1) % schedule.length];
        }
    }

    return totalPorts;
}

// Функция для обновления контента
function updateContent() {
    const totalPorts = calculatePorts(initialPorts, schedule, startYear, startWeek);
    const contentElement = document.querySelectorAll(".counter-montage");
    if (contentElement.length !== 0) {
        for (let counter of contentElement) {
            counter.textContent = totalPorts;
        }
    }
}

// Обновление контента при загрузке страницы
addEventListener("DOMContentLoaded", updateContent);

// Обновление контента каждую неделю
setInterval(updateContent, 7 * 24 * 60 * 60 * 1000); // Обновление каждую неделю
