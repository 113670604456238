document.querySelector(".popup-container").addEventListener("click", (event) => {
    const insidePopup = event.composedPath().includes(document.querySelector(".popup"));
    if (!insidePopup) {
        document.querySelector(".popup-container").classList.remove("popup-container_active");
        document.body.style.overflow = "";
    }
    if (event.target == document.querySelector(".popup__cross")) {
        document.querySelector(".popup-container").classList.remove("popup-container_active");
        document.body.style.overflow = "";
    }
});
