import "./copyright";

const today = new Date();

const formattedDate = today.toLocaleDateString("ru-RU", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
});

const dateElement = document.querySelectorAll(".date");

if (dateElement.length !== 0) {
    for (let date of dateElement) {
        date.textContent = formattedDate;
    }
}
