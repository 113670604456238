import "swiper/swiper-bundle.min.css";
import { Swiper } from "swiper";
import { Navigation } from "swiper/modules";
import homeIcon from "/public/icons/home.svg";
import productSlow from "/public/images/products/product-slow.webp";
import product1 from "/public/images/products/product-1.webp";
import product2 from "/public/images/products/product-2.webp";
import product3 from "/public/images/products/product-3.webp";

// слайдер проектов
const swiper1 = new Swiper(".projects-swiper", {
    modules: [Navigation],
    breakpoints: {
        0: {
            slidesPerView: 1.15,
        },
        561: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 4,
        },
    },
    spaceBetween: 20,
    navigation: {
        nextEl: ".projects__swiper-btn-next",
        prevEl: ".projects__swiper-btn-prev",
    },
});

// слайдер с табами
const swiper2 = new Swiper(".stations__swiper-cards", {
    modules: [Navigation],
    breakpoints: {
        0: {
            slidesPerView: 1.15,
        },
        561: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 4,
        },
    },
    spaceBetween: 25,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});

function mainProductHtml(image, title, text) {
    return `
        <div class="stations__card">
            <img src="${image}" loading="lazy" width="376" height="447" alt="станции" />
            <div class="stations__description">
                <img src="${homeIcon}" alt="используйте дома" loading="lazy" />
                <h4 class="stations__title">${title}</h4>
                ${text}
            </div>
        </div>`;
}

function slaiderProductHtml(v, model, image, text) {
    return `<div class="swiper-slide stations__swiper-product">
        <div>
            <span class="stations__product-kilowatt">${v} кВт</span>
            <span class="stations__product-assent-text">${model}</span>
        </div>
        <img
            src="${image}"
            loading="lazy"
            alt="станция 1"
        />
        <p>${text}</p>
        <button class="order">Заказать</button>
    </div>`;
}

const products = {
    top: [
        {
            image: productSlow,
            title: "Станции для домашнего использования до <b>22</b>кВт",
            text: `Зарядные станции (медленные) для домашнего использования. Применяются в частных домах, на территории 
                    коммерческих организаций и во дворах многоквартирников. Дешевый вариант для ситуаций когда нет требований к 
                    быстрой зарядке электромобиля. Среднее время зарядки 4-7 часов до 80%`,
        },
        {
            image: product1,
            title: "Станции для быстрой до <b>180</b>кВт",
            text: `Быстрые зарядные станции (Supercharger). Способны зарядить электрокар за 1-2 часа до 80%. 
                    Применяются в торговых и бизнес центрах, на трассах и открытых уличных пространствах. Более дорогие, 
                    но очень быстрые зарядные станции. Функция одновременной зарядки разными кабелями.`,
        },
        {
            image: product2,
            title: "Подберите необходимые аксессуары для электромобиля.",
            text: `Кабели, переходники и разъемы для зарядных станций являются важными элементами инфраструктуры для
                    электромобилей. Они обеспечивают передачу электроэнергии от зарядной станции к электромобилю, позволяя
                    водителям быстро и удобно заряжать свои автомобили.`,
        },
    ],
    bottom: [
        [
            {
                v: "22",
                model: "T",
                image: product1,
                text: "fsfss",
            },
            {
                v: "22",
                model: "T",
                image: product2,
                text: "fsfss",
            },
            {
                v: "22",
                model: "T",
                image: product3,
                text: "fsfss",
            },
            {
                v: "22",
                model: "T",
                image: product2,
                text: "fsfss",
            },
            {
                v: "22",
                model: "T",
                image: product3,
                text: "fsfss",
            },
        ],
        [
            {
                v: "2",
                model: "T",
                image: productSlow,
                text: "fsfss",
            },
            {
                v: "2",
                model: "T",
                image: product2,
                text: "fsfss",
            },
            {
                v: "2",
                model: "T",
                image: product3,
                text: "fsfss",
            },
            {
                v: "2",
                model: "T",
                image: product2,
                text: "fsfss",
            },
            {
                v: "2",
                model: "T",
                image: product1,
                text: "fsfss",
            },
        ],
        [
            {
                v: "",
                model: "T",
                image: product1,
                text: "fsfss",
            },
            {
                v: "",
                model: "T",
                image: product1,
                text: "fsfss",
            },
            {
                v: "",
                model: "T",
                image: product1,
                text: "fsfss",
            },
            {
                v: "",
                model: "T",
                image: product1,
                text: "fsfss",
            },
            {
                v: "",
                model: "T",
                image: product1,
                text: "fsfss",
            },
        ],
    ],
};

function renderProducts(index) {
    const productHtml = mainProductHtml(products.top[index].image, products.top[index].title, products.top[index].text);
    document.querySelector(".stations__main-product").innerHTML = productHtml;
    products.bottom[index].forEach(({ v, model, image, text }) => {
        document.querySelector(".stations__swiper-wrapper").innerHTML += slaiderProductHtml(v, model, image, text);
    });
    for (let btn of document.querySelectorAll(".order")) {
        btn.addEventListener("click", (event) => {
            const product = event.target.parentElement;
            document.querySelector(".popup img").src = product.querySelector("img").src;
            document.querySelector(".popup h3").innerHTML = `Заявка на товар: ${product.querySelector("p").textContent}`;
            document.querySelector(".popup-container").classList.add("popup-container_active");
            document.body.style.overflow = "hidden";
        });
    }
}
renderProducts(0);

document.querySelector(".tabs").addEventListener("click", (event) => {
    const tab = event.target.closest(".tabs__item");
    if (!tab || tab.classList.contains("tabs__item_active")) return;

    document.querySelector(".tabs__item_active").classList.remove("tabs__item_active");
    tab.classList.add("tabs__item_active");
    document.querySelector(".stations__swiper-wrapper").innerHTML = "";
    const index = parseInt(tab.id, 10) - 1;
    renderProducts(index);
    swiper2.update();
});

// главный блок слайдер
for (let item of document.querySelectorAll(".hero__slide")) {
    item.addEventListener("click", (event) => {
        const mainImage = document.querySelector(".hero__image").src;
        const slideImage = item.querySelector("img").src;
        item.querySelector("img").src = mainImage;
        document.querySelector(".hero__image").src = slideImage;
    });
}
